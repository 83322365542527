/*
 * API REQUEST TEMPLATE
 var data = '{"data":"value"}';
 $.ajax({
 url: "http://devoffice.local.ua/api/URL",
 type: "POST",
 dataType: 'json',
 data: JSON.stringify(data),
 processData: false,
 contentType: 'application/json',
 crossDomain: true,
 async: false,
 success: function (data) {
 // ok
 },
 error: function (xhr, ajaxOptions, thrownError) {
 console.log(xhr.statusText);
 }
 });
 * */

$(document).on('click', '.lazyloadbutton', function () {
    $("img.lazy").lazyload();
});

$(function () {
    $('#tree1').tree({
        dragAndDrop: true,
        autoOpen: 0,
        selectable: true,
        onCanMoveTo: function (moved_node, target_node, position) {
            var max_level = 4; // максимальный уровень вложености
            var can_include = max_level - target_node.getLevel();
            // рядом с первым уровнем можно разместить ноду с внуками (2)
            // рядом со вторым уровнем можно разместит ноду с детьмы (1)
            // рядом с третим уровнем можно разместить ноду без детей(0)
            var height_node = 0; //количество уровней у переносимой ноды
            if (position == 'inside') {
                can_include = can_include - 1;
                // внутрь первого уровня можно разместить ноду с детьмы, но без внуков (1)
                // внутрь второго уровня можно разместить ноду без детей(0)
                // внутрь третего уровня ничего перенсти нельзя (-1)
            }
            var flag = false;
            if (moved_node.children.length) {
                // если дети есть ищем внуков
                for (var i = 0; i < moved_node.children.length; i++) {
                    if (moved_node.children[i].children.length) {
                        flag = true;
                    }
                }
                if (flag) {
                    height_node = 2; // есть внуки
                } else {
                    height_node = 1; // только дети
                }
            }
            else {
                height_node = 0; // нет детей
            }

            return !!((can_include >= 0) && (height_node <= can_include));
        },
        onLoadFailed: function (response) {
            $('#tree1').html('Can`t load data');
        }
    });

    $('#tree1').bind(
        'tree.select',
        function (event) {
            if (event.node) {
                // node was selected
                var node = event.node;
                $.post(
                    "/catalogCategory/getNode",
                    {
                        node: node.id
                    }, function (data) {
                        if (data) {
                            $('.node-edit').html(data);
                        } else {
                            console.log('no data');
                        }
                    }
                );
            }
            else {
                // event.node is null
                // a node was deselected
                // e.previous_node contains the deselected node
            }
        }
    );

    $('#tree1').bind(
        'tree.move',
        function (event) {
            event.preventDefault();
            var moved_node = event.move_info.moved_node;
            var target_node = event.move_info.target_node;
            var position = event.move_info.position;

            var max_level = 4; // максимальный уровень вложености
            var can_include = max_level - target_node.getLevel();
            // рядом с первым уровнем можно разместить ноду с внуками (2)
            // рядом со вторым уровнем можно разместит ноду с детьмы (1)
            // рядом с третим уровнем можно разместить ноду без детей(0)
            var height_node = 0; //количество уровней у переносимой ноды
            if (position == 'inside') {
                can_include = can_include - 1;
                // внутрь первого уровня можно разместить ноду с детьмы, но без внуков (1)
                // внутрь второго уровня можно разместить ноду без детей(0)
                // внутрь третего уровня ничего перенсти нельзя (-1)
            }
            var flag = false;
            var grandchild_count = 0;
            if (moved_node.children.length) {
                // если дети есть ищем внуков
                for (var i = 0; i < moved_node.children.length; i++) {
                    if (moved_node.children[i].children.length) {
                        flag = true;
                        grandchild_count += moved_node.children[i].children.length;
                    }
                }
                if (flag) {
                    height_node = 2; // есть внуки
                } else {
                    height_node = 1; // только дети
                }
            }
            else {
                height_node = 0; // нет детей
            }

            var $alert_string = 'Перемеcтить ' +
                moved_node.name + ' (подкатегорий: ' +
                moved_node.children.length + ', внуков: ' + grandchild_count +
                ' ) ' +
                position + ' ' +
                target_node.name + ' (lvl ' +
                target_node.getLevel() + ' ) ?';

            if ((can_include >= 0) && (height_node <= can_include)) {
                if (confirm($alert_string)) {

                    $.post(
                        "/catalogCategory/moveNode",
                        {
                            node: moved_node.id,
                            target: target_node.id,
                            direction: position
                        }, function (data) {
                            if (data.status == 'ok') {
                                //event.move_info.do_move();
                                $('#tree1').tree('reload');
                            } else {
                                console.log(data.status);
                            }
                        }
                    );
                }
            }
        }
    );

    // автофокус для элемента с классом focus - это должен быть input
    $('.focus').focus();

});

$(document).on('click', '#ajaxEditNode', function (e) {
    e.preventDefault();
    var form = $(this).parent('form');
    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        success: function (data, textStatus) {
            $('#tree1').tree('reload');
            $(form).parent().html('');
        }
    });
});

$(document).on('click', '.ajaxAddNode', function (e) {
    e.preventDefault();
    var btn = $(this);
    var url = btn.attr('href');
    $.ajax({
        type: "POST",
        url: url,
        success: function (data, textStatus) {
            $('.node-edit').html(data);
        }
    });
});

$(document).on('click', '.ajaxDeleteNode', function (e) {
    e.preventDefault();
    var btn = $(this);
    var url = btn.attr('href');
    $.ajax({
        type: "POST",
        url: url,
        success: function (data, textStatus) {
            $('#tree1').tree('reload');
            $('.node-edit').html('');
        }
    });
});


$(window).resize(bundleMenu);
$(window).resize(adjust_submenu_height);

$(document).on('shown.bs.dropdown', '#main_menu', bundleMenu);

$(document).on('shown.bs.dropdown', '#main_menu', adjust_submenu_height);

function adjust_submenu_height() {

    // cмещает выпадающие подпункты максимально вверх, если не влазят - скрол

    $('.dropdown-menu .dropdown-menu').each(function (i, element) {

        var parent = $(element).parent();
        var header_height = $('#main_menu').height();

        var max_offset = $(parent).offset().top - header_height - 6;
        var need_offset = $(element).height() - $(parent).height();
        $(element).css('top', -1 * Math.min(max_offset, need_offset));
        var max_height = $(window).height() - header_height;
        $(element).css({
            'height': 'auto',
            'overflow-x': 'hidden',
            'max-height': max_height
        });
    });
}

var mainMenuSize;

function bundleMenu() {

    // cворачивает раскрытые подпункты если меню не влазит
    var header_height = $('#main_menu').height();
    var menu_height = $('[role=menu]').height();
    var list_submenu = $(".dropdown-list-submenu");

    if (!mainMenuSize) {
        mainMenuSize = menu_height + header_height + 12;
    }

    if ($(window).height() < mainMenuSize) {

        if (!$(list_submenu).hasClass('dropdown-submenu')) {
            $(list_submenu).addClass('dropdown-submenu');
            $(".submenu-item").wrap('<li class="menu-item"></li>');

            $(list_submenu).each(function (indx, element) {
                $(element).children(".menu-item").wrapAll('<ul class="dropdown-menu"></ul>');
            });
        }
    } else {

        if ($(list_submenu).hasClass('dropdown-submenu')) {
            $(list_submenu).removeClass('dropdown-submenu');
            $(".submenu-item").unwrap('<li class="menu-item"></li>');

            $(list_submenu).each(function (indx, element) {
                $(element).find(".submenu-item").first().unwrap('<ul class="dropdown-menu"></ul>');
            });
        }
    }
}

function getActiveFormName() {

    var modal = $('#modalForm');

    // как определить состояние модального окна?
    if ((modal.data('bs.modal') || {}).isShown) {
        return modal.data('formName');
    }

    return '';
}

window.setInterval(function () {

    // во время Тика можно передавать дополнительные данные
    var data = {};

    // например, будем передавать имя открытой Lockable формы для обновления блокировки
    data.lockableModalForm = getActiveFormName();

    $.post('/tick', {data: data}, function () {
        }, 'json')
        .fail(function (xhr, textStatus, errorThrown) {

            // в случае ошибки выводить сообщение и перезагружать страницу
            // текст ошибки брать из стандартного вывода
            alert($('.exception_title', xhr.responseText).text());
            location.reload();
        });

}, 60000);

function processModalResponse(data) {

    var isJson = true;
    if (data.responseText) {
        data = data.responseText;
    }
    if (jQuery.type(data) === 'string') {
        try {
            data = jQuery.parseJSON(data);
        }
        catch (err) {
            console.log(err);
            isJson = false;
        }
    }

    if (isJson) {

        if (data.status == 'fail') {
            return false;
        } else if (data.status == 'ERROR') {
            return false;
        }

        var modal = $('#modalForm');

        if (data.action == 'process_invoker_action' && data.owner_id) {

            var invoker = $('#modalFormToggle[data-owner-id="' + data.owner_id + '"]');
            $(invoker)
                .addClass('ajaxAction')
                .removeAttr('id')
                .attr('href', $(invoker).data('postAction'))
                .click();

        }
        if (data.action == 'reload_page') {

            window.location.reload();

        } else if (data.action == 'update_comment') {

            // FIXME make universal action
            updateComment(data.purch_id, data.comment);

        } else if (data.action == 'callback') {

            // выполнить пользовательскую функцию, расположенную в форме модального окна
            window["callbackModal"]();
        } else if (data.action == 'callback_static') {

            // выполнить пользовательскую функцию, расположенную в форме
            window[data.actionCallback](data.actionCallbackParams);
        }

        if (data.action == 'reload_modal') {

            if (data.reload_action) {
                $(modal).data('href', data.reload_action);
            }
            reloadModal($(modal));

        } else if (data.status == 'OK') {
            if ((modal.data('bs.modal') || {}).isShown) {
                $(modal).modal('hide');
            }
        }

        return (data.status == 'ok');
    }
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// AUTO HEIGHT BLOCKS
$(function () {
    if ($('.auto-height').length) {

        var windowH = $(window).height();
        var wrapperH = $('.auto-height').height();
        if (windowH < wrapperH) {
            $('.auto-height').css({'height': ($(window).height() - 88) + 'px'});
        }
        $(window).resize(function () {
            var windowH = $(window).height();
            var wrapperH = $('.auto-height').height();
            if (windowH < wrapperH) {
                $('.auto-height').css({'height': ($(window).height() - 88) + 'px'});
            }
        });
    }
});

$(document).on('shown.bs.modal', '#modalForm', function () {
    // fix for handsontable copyable option
    $(document).off('focusin.bs.modal');
});
$(document).on('hide.bs.modal', '#modalForm', function () {
    // обработка закрытия модальной формы

    // снять блокировку эксклюзивную
    var modal = $(this);
    $.post('/removeFormBlock', {formName: $(modal).data('formName')}, function () {
        $(modal).data('formName', '')
    });
});

function onModalLoad(modal) {
    $(modal).find('#modalDeleteButton').hide();
    $(modal).find('#modalPostButton').hide();

    if ($(modal).data('noModalSubmit') || $(modal).find('form').hasClass('no-modal-submit')) {
        $(modal).find('#modalSaveButton').hide();
    } else {
        $(modal).find('#modalSaveButton').show().removeAttr('disabled');
    }

    if ($(modal).find('form').hasClass('show-post-button')) {
        $(modal).find('#modalPostButton').show().removeAttr('disabled');
    }
    if (!$(modal).data('noDeleteButton') && $(modal).find('form').hasClass('show-delete-button')) {
        $(modal).find('#modalDeleteButton').show().removeAttr('disabled');
    }

    $('.multi-select2').select2();

    // костыль для поля выбора даты
    if ($('.dateTimeField').length) {
        $(".dateTimeField").datepicker({
            dateFormat: "yyyy-mm-dd",
            orientation: "top auto",
            format: "yyyy-mm-dd"
        });
    }

    initTabs();
}

function reloadModal(modal) {

    // сначала показать форму
    modal.modal('show');

    // потом очистить содержимое
    modal.find('.modal-body').html('');
    modal.find('.modal-locked').html('');

    // потом загрузить содержимое по ajax
    $.get($(modal).data('href'), function (data) {
        var content;

        // если ответ сервера JSON - разобрать его
        if (jQuery.type(data) === 'object') {

            content = data.content;

            // обработать переданные параметры формы

            // название формы
            $(modal).data('formName', data.formName);
            if (data.blockData) {
                modal.find('.modal-locked').html(data.blockTitle);
                $(modal).data('noModalSubmit', true);
                $(modal).data('noDeleteButton', true);
            }

        } else {
            content = data;
        }

        modal.find('.modal-body').html(content);
        onModalLoad(modal);
    });
}

$(document).on('click', '#modalFormToggle', function (e) {
    e.preventDefault();
    var modal = $('#modalForm');
    var el = $(this);

    $(modal).data('href', $(el).attr('href'));
    $(modal).data('noModalSubmit', $(el).hasClass('no-modal-submit'));
    $(modal).data('formName', '');

    reloadModal(modal);

    return false; // prevent other actions
});

function beforeModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').attr('disabled', 'disabled');
    $(modal).find('#modalPostButton').attr('disabled', 'disabled');
    $(modal).find('#modalDeleteButton').attr('disabled', 'disabled');
}

function afterModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').show().removeAttr('disabled');
    $(modal).find('#modalPostButton').removeAttr('disabled');
    $(modal).find('#modalDeleteButton').removeAttr('disabled');
}

function submitModalForm() {

    beforeModalFormSubmit();

    var form = $('.modal-body').find('form');
    var modal = $('#modalForm');

    if ($(form).hasClass('optionFormSubmitNoAjaxClass')) {
        $(form).submit();
        return true;
    }

    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        complete: function (data) {
            processModalResponse(data);
        }
    }).fail(function (data) {

        if (data.status == 422) {

            var errors = data.responseJSON;
            //всем полям  статус ок
            $(modal).find('div.form-group').removeClass('has-error has-feedback');
            $(modal).find('div.form-group').addClass('has-success has-feedback');
            $('span.glyphicon-remove').remove();
            $('<span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>').appendTo($(modal).find('div.form-group'));

            // удалить все окна об ошибках

            $('div.form-control-message').remove();

            $.each(errors, function (key, value) {
                $(modal).find('#' + key).parent('div.form-group').removeClass('has-success has-feedback');
                $(modal).find('#' + key).parent('div.form-group').addClass('has-error has-feedback');
                $(modal).find('#' + key).siblings('span.glyphicon-ok').remove();
                $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>').appendTo($(modal).find('#' + key).parent('div.form-group'));
                $('<div class="alert alert-danger form-control-message" role="alert"></div>').appendTo($(modal).find('#' + key).parent('div.form-group'));
                $('div.form-control-message').append(value.toString());
            });

            afterModalFormSubmit();
        }
        else {
            console.log(data.errors);
        }
    });

}

$(document).on('click', '#modalPostButton', function () {
    $('input[name=posted]').val(true);
    submitModalForm();
});

$(document).on('click', '#modalDeleteButton', function () {
    if (confirm('Are you sure?')) {
        $('input[name=deletion_mark]').val(true);
        submitModalForm();
    }
});

$(document).on('click', '#modalSaveButton', function () {
    submitModalForm();
});

$(document).on('change', '.change_status', function (e) {

    var el = $(this);
    var id = $(el).data('ownerId'),
        button = '#' + id,
        we_check = 0,
        ch_check = 0;
    if ($(el).hasClass('we_check')) {
        if ($(el).prop("checked") == true) {
            we_check = 1;
        } else {
            we_check = 0;
        }
        ch_check = -1;
    } else {
        if ($(el).prop("checked") == true) {
            ch_check = 1;
        } else {
            ch_check = 0;
        }
        we_check = -1;
    }

    $.post('/purchaseCheckOrder', {
        id: id,
        we_check: we_check,
        ch_check: ch_check
    }, function (response) {
        if (response == '2') {
            $(button).removeClass('disabled');
        }
        else {
            $(button).addClass('disabled');
        }
    });
    return false;

});

$(document).on('click', '.tableRowToggler', function (e) {
    var owner_id = $(this).data('owner');
    var container = $('.sub-table[data-owner=' + owner_id + ']');
    $(container).toggleClass('hidden');

    if ($(container).hasClass('load-remote') && !$(container).hasClass('hidden')) {
        var status = $('input[name="statuses"]').val();
        var currentRoute = $('input[name="currentRoute"]').val();
        $.post($('input[name="table-expand-action"]').val(), {
            status: status,
            manager_id: owner_id,
            currentRoute: currentRoute
        }, function (response) {
            $(container).children('td.no-padding').html(response);
        });
    }
});

$(document).on('click', '.tableToggler', function (e) {

    $('.tableRowToggler').each(function (index, item) {
        $(item).click();
    });
});

$(document).on('click', '.ajaxAction', function (e) {
    e.preventDefault();

    var el = $(this);

    if (el.is('a')) {

        if ($(el).data('confirmation')) {
            if (!confirm($(el).data('confirmation'))) {
                return false;
            }
        }

        var elContent = $(el).html();

        $(el).html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');

        var ownerId = $(el).data('ownerId');
        var someData = [];
        var objects = $('.data-container[data-owner-id="' + ownerId + '"] .data-ajax-post').toArray();
        var requiredError = false;

        if (!$(el).hasClass('no-requires')) {
            for (var i = 0; i < objects.length; i++) {
                someData.push([$(objects[i]).attr('name'), $(objects[i]).val()]);

                var value = $(objects[i]).val();
                if ($(objects[i]).hasClass('required') && (!value || value == 0)) {
                    $(objects[i]).tooltip({
                        placement: 'top',
                        trigger: 'manual',
                        title: 'Field required'
                    }).tooltip('show');
                    requiredError = true;
                }
            }
        }

        if (requiredError) {
            $(el).html(elContent).addClass('btn-warning');
            return false;
        }
        $.post($(el).attr('href'), {
            ownerId: ownerId,
            'someData': someData
        }, function (data) {
            $(el).html(elContent);
            if (data == 'remove_owner_table_row') {
                $('*[data-owner-id="' + ownerId + '"]').addClass('bg-warning').fadeOut();
            } else if (data == 'false') {
                $(el).addClass('btn-warning');
            } else if (data.status == 'ERROR') {
                $(el).addClass('btn-warning');
            } else if (data.action == 'callback_static') {
                // выполнить пользовательскую функцию, расположенную в форме
                window[data.actionCallback](data.actionCallbackParams);
            } else if (data == 'callback') {
                window[$(el).data('callback')](el);
            } else if (data.action == 'reload_modal') {
                var modal = $('#modalForm');
                if (data.reload_action) {
                    $(modal).data('href', data.reload_action);
                }
                reloadModal($(modal));
            } else if (data.action == 'reload_page') {
                window.location.reload();
            }
        });

    }
});

$(document).on('change', 'input.autoCommit', function () {
    var data = {
        ownerId: $(this).data('ownerId'),
        field: $(this).attr('name'),
        value: $(this).val()
    };
    var element = $(this);
    element.removeClass('bg-success').removeClass('bg-warning');
    $.post($(this).data('action'), data, function (data) {
        if (data.status == 'OK') {
            element.addClass('bg-success');
            processModalResponse(data)
        } else {
            element.addClass('bg-warning');
        }
    });
});

$(document).on('change', '.required', function () {
    $(this).tooltip('hide');
});

$(document).on('change', '.not-greater', function () {
    var defaultValue = parseInt($(this).prop('defaultValue'));
    if (defaultValue < parseInt($(this).val())) {

        if (confirm($(this).data('confirmationText'))) {
            $('.ajaxAction[data-owner-id="' + $(this).data('ownerId') + '"][href="' + $(this).data('confirmationAction') + '"]').click();
        } else {
            $(this).val(defaultValue).addClass('bg-warning');
        }
    } else {
        $(this).removeClass('bg-warning');
    }
});

$(document).on('click', '.log-row-view', function (e) {
    var recordId = $(this).data('rowId');
    var url = $('*[data-url]').data('url') + '/' + recordId;
    var container = $('.ajaxContainer');
    $(container).addClass('active-0');
    $.post(url, null, function (data) {
        $(container).html(data);
        $(container).removeClass('active-0');
    });
});

$(document).on('click', '.packingListUpiInTrash', function (e) {
    e.preventDefault();
    var id = $(this).attr('id');
    $('<input>').attr({
        type: 'hidden',
        name: 'removeUpi[]',
        value: id
    }).appendTo('form');
    $('*[data-owner-id="' + id + '"]').addClass('tableRowRemoved');
});

$(document).on('click', '.sellerOrderSkuInTrash', function (e) {
    e.preventDefault();
    var id = $(this).attr('id');
    $('<input>').attr({
        type: 'hidden',
        name: 'removeSku[]',
        value: id
    }).appendTo('form');
    $('*[data-owner-id="' + id + '"]').addClass('tableRowRemoved');
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

Element.prototype.hasClass = function (className) {
    return this.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(this.className);
};

$(function () {
    if ($("#datePickerStart").length) {
        $("#datePickerStart").datepicker({
            dateFormat: "yy-mm-dd",
            endDate: '-0d',
            orientation: "top auto",
            minViewMode: document.getElementById('datePickerStart').hasClass('minViewMode') ? 1 : 0,
            format: document.getElementById('datePickerStart').hasClass('minViewMode') ? "MM yyyy" : "yy-mm-dd"
        }).on('changeDate', function (e) {
            this.form.submit();
        });
    }
    if ($("#datePickerEnd").length) {
        $("#datePickerEnd").datepicker({
            dateFormat: "yy-mm-dd",
            endDate: '-0d',
            orientation: "top auto",
            minViewMode: document.getElementById('datePickerEnd').hasClass('minViewMode') ? 1 : 0,
            format: document.getElementById('datePickerEnd').hasClass('minViewMode') ? "MM yyyy" : "yy-mm-dd"
        }).on('changeDate', function (e) {
            this.form.submit();
        });
    }
});

$(function () {
    if ($("#datePicker_day").length) {
        $("#datePicker_day").datepicker({
            dateFormat: "yyyy-mm-dd",
            endDate: '-0d',
            orientation: "top auto",
            minViewMode: 0,
            format: "yyyy-mm-dd"
        }).on('changeDate', function (e) {
            this.form.submit();
        });
    }
});

$(document).on('affixed.bs.affix', function () {
    $('.affix').width($('.left-container').width());
});

$(document).on('keyup', '.expandableTextarea', function () {
    while ($(this).outerHeight() < this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth"))) {
        $(this).height($(this).height() + 1);
    }
});

$(document).on('change', '#money-transaction-sender-select', function () {
    $('input[name=amount]').val($(this).find(':selected').data('accountBalance'));
});

function setPaid(element) {
    var owner_id = $(element).data('owner');
    $('tr[data-owner="' + owner_id + '"]').find('.posted-status').html('Paid');
    $(element).detach();
}

// TODO finish this
$(document).on('click', '.packingListAddRow', function (e) {
    e.preventDefault();
    reloadModal($('#modalForm'));
});

$(document).on('click', '#upiOwnerChangeFindButton', function (e) {
    e.preventDefault();
    var action = $(this).attr('action');
    var upi_code = $('input[name="upi_code"]').val();
    $.ajax({
        type: "POST",
        url: action,
        data: {upi_code: upi_code},
        complete: function (data) {
            $('input[name="owner"]').val(jQuery.parseJSON(data.responseText));
            $('#modalForm').find('.hidden').removeClass('hidden');
        }
    });
});

$(document).on('change', '#upiOwnerChangeSellerSelect', function () {
    var action = $(this).attr('action');
    var seller_id = $(this).val();
    var shelfs = $('#upiOwnerChangeShelfSelect');
    $.ajax({
        dataType: "json",
        type: "POST",
        url: action,
        data: {seller_id: seller_id},
        success: function (data) {
            $(shelfs).html('');
            $.each(data, function (key, value) {
                $(shelfs).append(
                    $("<option></option>")
                        .attr("value", key)
                        .text(value)
                );
            });
        }
    });
});

function processCrawlerOrderAsinAdd(form) {
    var document_id = $('input[name="document_id"]').val();
    $.ajax({
        dataType: "json",
        type: "POST",
        url: $(form).attr('action'),
        data: form.serialize(),
        success: function (data) {

            if (!document_id) {
                $('input[name="document_id"]').val(data.document_id);
                $(form).attr('action', $(form).attr('action') + '/' + data.document_id);
            }

            $('input[name="asin"]').val('');
            $('input[name="upi_code"]').val('');

            reloadCrawlerOrderAsinTable(data);
        }
    });
    return true;
}

function reloadCrawlerOrderAsinTable(data) {
    // TODO - добавление новой строки в таблицу делать по шаблону
    var table_body = $('#crawler_order_asins tbody');
    $(table_body).html('');
    $.each(data.asins, function (key, value) {
        $(table_body).append('<tr><td></td>' +
            '<td>' + value.asin + '</td>' +
            '<td>' + value.supplier_id + ' - ' + value.supplier_name + '</td>' +
            '</tr>');
    });
}

$(document).on('click', '#crawlerOrderAsinAddButton', function () {
    processCrawlerOrderAsinAdd($('.modal-body').find('form'));
});

$(document).on('click', '.crawlerOrderAsinInTrash', function (e) {
    e.preventDefault();
    var id = $(this).attr('id');
    $('<input>').attr({
        type: 'hidden',
        name: 'removeAsin[]',
        value: id
    }).appendTo('form');
    $('*[data-owner-id="' + id + '"]').addClass('tableRowRemoved');
});

function processInventoryUpiAdd(form) {
    var document_id = $('input[name="document_id"]').val();
    $.ajax({
        dataType: "json",
        type: "POST",
        url: $(form).attr('action'),
        data: form.serialize(),
        success: function (data) {

            if (!document_id) {
                $('input[name="document_id"]').val(data.document_id);
                $(form).attr('action', $(form).attr('action') + '/' + data.document_id);
            }

            $('input[name="upi_code"]').val('');
            $('input[name="new_in_stock"]').val('');
            $('input[name="note"]').val('');

            reloadInventoryUpiTable(data);
        }
    });
    return true;
}

function reloadInventoryUpiTable(data) {
    var table_body = $('#inventory_upis tbody');
    $(table_body).html('');
    $.each(data.upi, function (key, value) {
        $(table_body).append('<tr><td></td>' +
            '<td>' + value.upi_code + '</td>' +
            '<td>' + value.sku + '</td>' +
            '<td>' + value.seller_name + '</td>' +
            '<td>' + value.current_in_stock + '</td>' +
            '<td>' + value.new_in_stock + '</td>' +
            '<td>' + value.note + '</td>' +
            '</tr>');
    });
}

$(document).on('click', '#inventoryUpiAddButton', function () {
    processInventoryUpiAdd($('.modal-body').find('form'));
});

$(document).on('click', '.inventoryUpiInTrash', function (e) {
    e.preventDefault();
    var id = $(this).attr('id');
    $('<input>').attr({
        type: 'hidden',
        name: 'removeUpi[]',
        value: id
    }).appendTo('form');
    $('*[data-owner-id="' + id + '"]').addClass('tableRowRemoved');
});

// ACL

function aclClearAllHighlights() {
    $('input[name="acl-user-checkbox"]').bootstrapSwitch('state', false, true);
    $('input[name="acl-permission-checkbox"]').bootstrapSwitch('state', false, true);
    $('.acl-permissions li').removeClass('active');
    $('.acl-users li').removeClass('active');
}

$(document).on('click', '.acl-users li', function (e) {
    e.preventDefault();
    aclClearAllHighlights();
    $(this).addClass('active');
    $('.acl-user-checkbox-block').addClass('hidden');
    $('.acl-permission-checkbox-block').removeClass('hidden');
    // подсветить permissions выбранного пользователя
    $.getJSON("acl/getUserPermissions/" + $(this).attr('id'), function (data) {
        $.each(data, function (index, value) {
            $('input[name="acl-permission-checkbox"][id="' + value + '"]').bootstrapSwitch('state', true, true);
        })
    });
});

$(document).on('click', '.acl-permissions li', function (e) {
    if ($(e.target).is('span')) {
        return true;
    }
    e.preventDefault();
    aclClearAllHighlights();
    $(this).addClass('active');
    $('.acl-user-checkbox-block').removeClass('hidden');
    $('.acl-permission-checkbox-block').addClass('hidden');
    // подсветить пользователей с выбранным permission
    $.getJSON("acl/getPermissionsUsers/" + $(this).attr('id'), function (data) {
        $.each(data, function (index, value) {
            $('input[name="acl-user-checkbox"][id="' + value + '"]').bootstrapSwitch('state', true, true);
        })
    });
});

$(function () {
    $("[name='acl-user-checkbox']").bootstrapSwitch({size: 'mini'});
    $("[name='acl-permission-checkbox']").bootstrapSwitch({size: 'mini'});
});

$(document).on('switchChange.bootstrapSwitch', 'input[name="acl-user-checkbox"]', function (event, state) {
    var user_id = $(this).attr('id');
    var permission_id = $('.acl-permissions>li[class *= "active"]').attr('id');
    $.ajax({
        type: "POST",
        url: $('.acl-users').data('toggleUrl'),
        data: {userId: user_id, permissionId: permission_id, state: state},
        complete: function (data) {
            // status == OK
        }
    });
});
$(document).on('switchChange.bootstrapSwitch', 'input[name="acl-permission-checkbox"]', function (event, state) {
    var permission_id = $(this).attr('id');
    var user_id = $('.acl-users>li[class *= "active"]').attr('id');
    $.ajax({
        type: "POST",
        url: $('.acl-permissions').data('toggleUrl'),
        data: {userId: user_id, permissionId: permission_id, state: state},
        complete: function (data) {
            // status == OK
        }
    });
});

$(document).on('click', '.schedule-item-link', function (e) {
    e.preventDefault();
    $('.schedule-item-form-container').load($(this).attr('href'), function (data) {
        var jobLogContainer = $('#scheduler-job-log-container');
        $(jobLogContainer).load($(jobLogContainer).data('action'), function (data) {
        });
    });
});

$(document).on('click', '#scheduler-job-log-container>ul.pagination>li>a', function (e) {
    e.preventDefault();
    var jobLogContainer = $('#scheduler-job-log-container');
    $(jobLogContainer).load($(this).attr('href'), function (data) {
    });
});

$(document).on('click', '.seller-shelf-move-link', function (e) {
    e.preventDefault();
    $(this).hide();
    $('div[data-owner-id="' + $(this).data('ownerId') + '"]').show();
});

$(document).on('click', '.seller-shelf-move-button', function (e) {
    alert('Not implemented yet');
    return false;
    var ownerId = $(this).data('ownerId');
    var action = $(this).data('action');
    var sellerRecipient = $('select[data-owner-id="' + ownerId + '"]').val();
    if (confirm('Are ypu sure?')) {
        $.post(action, {
            shelf_id: ownerId,
            seller_id: sellerRecipient
        }, function () {
            $('tr[data-owner-id="' + ownerId + '"]').hide();
        });
    }
});

$(document).on('click', '.sellerShelfActiveToggleLink', function (e) {
    e.preventDefault();
    var ownerId = $(this).data('ownerId');
    $.post($(this).attr('href'), {}, function (response) {
        $('tr[data-owner-id="' + ownerId + '"]').toggleClass('active-0');
    });
});

$(document).on('click', '.sellerActiveToggleLink', function (e) {
    e.preventDefault();
    var ownerId = $(this).data('ownerId');
    $.post($(this).attr('href'), {}, function (response) {
        $('tr[data-owner-id="' + ownerId + '"]').toggleClass('active-0');
    });
});

$(function () {
    $('.sticky-table-header').stickyTableHeaders({fixedOffset: $('.navbar-fixed-top')});
});

$(document).on('click', '.dropdown-submenu>a', function (e) {
    e.preventDefault();
    return false;
});

$(document).on('change', '.change_pool_url', function (e) {
    var el = $(this);
    $.post($(el).data('url'), {
        sku: $(el).data('sku'),
        url: $(el).val()
    }, function (data) {
        $('.sku' + $(el).data('sku')).attr('href', $(el).val());
    });
});

$(document).on('click', '.add_new_supplier', function (e) {
    e.preventDefault();
    var sku = $(this).data('sku'),
        el = $("input[name^='new_product_link" + sku + "']"),
        values = $.map(el, function (n, i) {
            return i;
        }).length,
        divId = sku + '_' + (values + 1);
    $('.supplier_links_block' + sku).append('<div id="' + divId + '" class="supplier_links_items"><div class="form-group"><label for="supplier_id">Supplier ' + (values + 1) + '</label>&nbsp;<input style="width:400px" class="form-control input-sm" placeholder="New Item Link" name="new_product_link' + sku + '[' + (values + 1) + ']" type="text" value="">&nbsp;<a href="#" class="delete_supplier" data-sku="' + sku + '" data-item="' + (values + 1) + '"><span class="glyphicon glyphicon-remove"></span></a></div></div>');
    return false;
});

$(document).on('click', '.delete_supplier', function (e) {
    e.preventDefault();
    var el = $(this),
        sku = $(el).data('sku'),
        name = $(el).data('item');
    if (name != '1') {
        $('#' + sku + '_' + name).remove();
    } else {
        alert('You can not remove first link!');
    }
    return false;
});

$(document).on('click', '.purchase_ajax_submit', function (e) {
    e.preventDefault();
    form = $(this).parent('form');
    url = $(form).attr('action');
    button = $(this);
    $(button).prop("disabled", true);

    $.ajax({
        type: "POST",
        url: url,
        data: $(form).serialize(), // serializes the form's elements.
        success: function (data) {
            var PR = '#PR_' + data.sku;
            var PW = '#PW_' + data.sku;
            var PC = '#PC_' + data.sku;
            $(PR).html(data.PR);
            $(PW).html(data.PW);
            $(PC).html(data.PC);
            var row = '#sku' + data.sku;
            $(row).removeClass('in');
            var item_link = '#link_' + data.sku;
            $(item_link).attr("href", data.item_link);
            $(button).prop("disabled", false);

        },
        fail: function (data) {
            alert('Error'); // show response from the php script.
            console.log(data);
            $(button).prop("disabled", false);
        }
    });

});

$(document).on('click', '#packingListFileLink', function (e) {
    $('#packingListFile').click();
    e.preventDefault();
});

$(document).on('change', '#packingListFile', function () {
    $('#packingListFileForm').submit();
});

$(document).on('click', '#packingListNewUpiButton', function () {
    var modal = $('#modalForm');
    var form = $(modal).find('form');
    $.post($(form).attr('action'), $(form).serialize(), function (response) {
        $(modal).data('href', response.modal_url);
        reloadModal(modal);
    });
});

$(document).on('click', '#packingListEditUpiButton', function () {
    var row = $(this).closest('tr');
    var ownerId = $(row).data('ownerId');
    var quantity = $(row).find('input[name="quantity"]').val();
    $.post($('input[name="editUpiAction"]').val(), {
        ownerId: ownerId,
        quantity: quantity
    }, function (response) {
        reloadModal($('#modalForm'));
    });
});

function loadTabContent(el) {
    if ($(el).length && $(el).data('route')) {
        var target = $(el).attr('href');
        // TODO show loading progress
        $(target).load($(el).data('route'), function (e) {
        });
    }
}

function reloadActiveTab() {
    loadTabContent($('.nav-tabs[role="tablist"]>li.active>a'));
}

function initTabs() {
    $('.nav-tabs').tab();

    $('.nav-tabs[role="tablist"]>li>a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        loadTabContent(e.target);
    });
}

$(function () {

    reloadActiveTab();

    initTabs();

});

$(document).on('change', '#shelfFilterSelect', function () {
    var form = $(this).parent('form');
    var action = $(form).attr('action');
    $.post(action, $(form).serialize(), function (response) {
        reloadActiveTab();
    });
});

$(document).on('dblclick', '*[data-dblclick-action]', function () {
    clearSelection();
    var modal = $('#modalForm');
    $(modal).data('href', $(this).data('dblclickAction'));
    reloadModal(modal);
});

function clearSelection() {
    if (document.selection && document.selection.empty) {
        document.selection.empty();
    } else if (window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
    }
}

$(document).on('click', '.table-list tr', function () {
    $('.table-list tr').removeClass('table-list-row-selected');
    $(this).addClass('table-list-row-selected');
});

$(document).on('click', '.pagination>li>a', function (e) {
    var parentTab = $(this).closest('div[role="tabpanel"]');
    if ($(parentTab).length) {
        var activeTabLink = $('.nav-tabs[role="tablist"]>li.active>a');
        $(activeTabLink).data('route', $(this).attr('href'));
        loadTabContent($(activeTabLink));
        e.preventDefault();
    }
});

$(document).on('click', '.unbindSkuFromPurchaseManager', function (e) {
    if (confirm('Realy?')) {
        e.preventDefault();
        var element = $(this);
        $.post($(element).attr('href'),
            {
                purch_id: $(this).attr('data-owner-id'),
            },
            function (data) {
                if (data.status = 'ok') {
                    var row = $(element).find("tr");
                    console.log(row);
                    $(element).closest('tr').hide(1000);
                } else {
                    console.log(data.status);
                }
            });
    }
});

$(document).on('click', '.waitingCheckbox', function (e) {

    e.preventDefault();
    var element = $(this);
    var status;
    if ($(element).prop("checked")) {
        status = 1;
    } else {
        status = 0;
    }
// FIXME hardcoded url
    $.post("purchasing/setAnswerCheckbox",
        {
            purch_id: $(this).attr('data-owner-id'),
            waiting_for_response: status
        },
        function (data) {

            if ($(element).prop('checked')) {
                $(element).prop('checked', false);
            }
            else {
                $(element).prop('checked', true);
            }
        });

});

function updateComment($purch_id, $comment) {

    // FIXME search element
    var el = 'a[href^= "purchasing/getCommentForm/' + $purch_id + '"]';
    if ($comment == null) {
        $comment = "";
    }
    $(el).text('Comment: ' + $comment);

}

// HANDONTABLE INIT

function handontable_init(instance) {
    instance.handsontable({
        data: data,
        columns: columns,
        height: $(window).height() - $('.content').offset().top - 27,
        copyable: true,
        stretchH: 'all',
        allowInvalid: false,
        observeChanges: true,
        allowInsertColumn: false,
        columnSorting: true,
        sortIndicator: true,
        contextMenu: ['row_above', 'remove_row'],
        fillHandle: false,
        rowHeaders: true,
        colHeaders: colHeaders,
        cells: cells,
        search: true,
        wordWrap: true
    });
}

$(document).ready(function () {
    var changed = 'no';

    $(document).on('change', 'input[name^="new_product_link"]', function () {
        changed = 'yes';
    });

    $(document).on('click', '.delete_supplier', function () {
        changed = 'yes';
    });

    $(document).on('click', '.add_new_supplier', function () {
        changed = 'yes';
    });

    $(window).on('beforeunload', function () {
        if (changed == 'yes') {
            return "Были внесены изменения, которые НЕ БЫЛИ сохранены";
        }
    });
    $(document).on('click', '.save_suppliers', function () {
        $(window).off('beforeunload');
    });
});